<template>
  <el-modal size="sm" :title="$t(module+'.product.modal.title')" id="market-place-product-create">
    <form @submit.prevent="newProduct()">
      <el-alert type="warning" v-if="formError !== false && formError != ''">
        <span v-html="formError"></span>
      </el-alert>
      <div class="form-group">
        <label for="name" class="label-required font-weight-bold">{{ $t(module+'.product.modal.name') }}</label>
        <input v-model="product.name" required type="text" class="form-control" id="name" :placeholder="$t(module+'.product.modal.palceholder.name')">
      </div>
      <div class="form-group">
        <label for="description" class="label-required font-weight-bold">{{ $t(module+'.product.modal.description') }}</label>
        <textarea v-model="product.description" required class="form-control" :placeholder="$t(module+'.product.modal.palceholder.description')"/>
      </div>
      <div class="form-group">
        <label for="fournisseur" class="label-required font-weight-bold">{{ $t(module+'.product.modal.fournisseur') }}</label>
        <input v-model="product.fournisseur" required type="text" class="form-control" id="fournisseur" :placeholder="$t(module+'.product.modal.palceholder.fournisseur')">
      </div>
      <div class="form-group">
        <label for="price" class="font-weight-bold">{{ $t(module+'.product.modal.price') }}</label>
        <input v-model="product.price" type="number" min="1" class="form-control" id="price" :placeholder="$t(module+'.product.modal.palceholder.price')">
      </div>
      <div class="form-group">
        <label for="categorie" class="font-weight-bold">{{ $t(module+'.product.modal.categorie') }}</label>
        <el-select :options="getCategories['categorie']" required :value="'value'" v-model="product.categorie" :placeholder="$t(module+'.product.modal.palceholder.categorie')" />
      </div>

      <div class="form-group" v-if="product.categorie">
        <label :for="'categories_' + product.categorie" class="font-weight-bold">{{ $t(module+'.product.modal.palceholder.categories_' + product.categorie) }}</label>
        <el-select :options="getCategories['categories_' + product.categorie]" :value="'value'" v-model="product['categories_' + product.categorie]" :placeholder="$t(module+'.product.modal.palceholder.categories_' + product.categorie)" />
      </div>

      <div class="form-group">
        <label for="url" class="font-weight-bold label-required">{{ $t(module+'.product.modal.url') }}</label>
        <input v-model="product.url" type="url" required class="form-control" id="url" :placeholder="$t(module+'.product.modal.palceholder.url')" pattern="https?://.*" size="30">
      </div>
      <div class="form-group">
        <label for="tribe" class=" font-weight-bold">{{ $t('titles.world_tribes') }}</label>
        <el-select :options="getTribes" :multiple="true" :value="'value'" v-model="product.tribe" :placeholder="$t('titles.world_tribes')" />
      </div>
      <div class="form-group">
        <label for="tag" class="label-requiredprice font-weight-bold">{{ $t(module+'.product.modal.tag') }}</label>
        <input-tag
          :required="false"
          v-model="product.tag"
          class="form-control"
          :placeholder="$t(module+'.product.modal.palceholder.tag')"
        />
      </div>
      <div class="file-upload-form">
        <label class="mt-2 mb-1 font-weight-bold label-required">
          {{ $t(module+'.product.modal.picture') }}
        </label>
        <label class="mt-2 mb-1" v-if="errorImage">
          {{ $t(module+'.product.modal.error.picture') }}
        </label>
        <input ref="pictureInput" class="d-none" type="file" @change="previewPicture" accept="image/gif, image/jpeg, image/png" />
        <div v-if="product.image">
          <el-avatar v-if="product.image.base64" :src="product.image.base64"></el-avatar>
          <el-avatar v-if="product.image.thumb" :src="product.image.thumb"></el-avatar>
          <div @click="changePicture()" class="ml-2 btn btn-sm btn-light">{{ $t(module+'.product.modal.picture_change') }}</div>
        </div>
        <div>
          <div @click="changePicture()" class="btn btn-sm btn-light" v-if="!product.image">{{ $t(module+'.product.modal.picture_add') }}</div>
        </div>
      </div>
      <div class="text-center">
        <button v-if="!product_loading" type="submit" class="btn btn-success">{{ $t(module+'.product.modal.save') }}</button>
        <div v-if="product_loading" class="text-primary pt-1 pb-2"><loader></loader></div>
      </div>
    </form>
  </el-modal>
</template>

<script>
/**
 * @group _ Module marketplace
 * This component for modal to create new product
 */

import _ from 'lodash'
import {
STORE_PRODUCT,
} from '../../mutations-types'

export default {
  name: "CreateProduct",
  data () {
    return  {
      module: 'modules.' + this.$route.meta.moduleName,
      product_loading: false,
      product: {
        name: null,
        description: null,
        fournisseur: null,
        price: null,
        categorie: null,
        tribe: [],
        tag: [],
        image: null,
        url: null
      },
      errorImage: false,
      formError: false
    }
  },
  methods: {
    /**
     * @vuese
     * This function to create new product
     */
    newProduct () {
      this.formError = ''
      if(this.product.name.trim() == "") {
        this.formError+= '' + this.$t(this.module+'.product.modal.error.name') + '<br/>'
      }
      if(this.product.description.trim() == "" ) {
        this.formError+= '' + this.$t(this.module+'.product.modal.error.description') + '<br/>'
      }
      if(this.product.fournisseur.trim() == "" ) {
        this.formError+= '' + this.$t(this.module+'.product.modal.error.fournisseur') + '<br/>'
      }
      if(this.product.image == null){
      this.formError+= this.$t(this.module+'.product.modal.error.picture')
      }
      if(this.formError == '') {
        this.formError = false
      } else {
        return
      }
      this.$store.dispatch('marketplace/'+STORE_PRODUCT, {data: this.product , world: this.$store.getters['auth/getWorld'].id}).then(resp => {
        this.notifSuccess(resp[0])
        this.closeModal('market-place-product-create')
        this.product =  {
          name: null,
          description: null,
          fournisseur: null,
          price: null,
          categorie: null,
          tribe: [],
          tag: [],
          image: null,
        }
      })
    },
    /**
     * @vuese
     * This function to return array for el-select
     */
    catSelect (data, categoryKey) {
      let list = []
      _.map(data, item => {
          list.push({ value: item, title: this.$t(this.module + '.product.categories.' + categoryKey + '.' + item) })
      })
      return list
    },
    /**
     * @vuese
     * This function to return array for el-select
     */
    objectSelect (data, key = null) {
      let list = []
      _.map(data, item => {
        if(key == null)
          list.push({ value: item, title: item })
        else
          list.push({ value: item[key.value], title: item[key.title] })
      })
      return list
    },
    /**
    * @vuese
    * change picher profile
    */
    changePicture () {
      this.errorImage = false
      this.product.image = null
      this.$refs.pictureInput.click()
    },

    async previewPicture (event) {
      // Reference to the DOM input element
      var input = event.target
      // Ensure that you have a file before attempting to read it
      if (input.files && input.files[0]) {
        // create a new FileReader to read this image and convert to base64 format
        var reader = new FileReader()
        // Define a callback function to run, when FileReader finishes its job
        reader.onload = (e) => {
          // Note: arrow function used here, so that "this.imageData" refers to the imageData of Vue component
          // Read image as base64 and set to imageData
          this.product.image = {}
          this.product.image.base64 = e.target.result
        }
        // Start the reader job - read file as a data url (base64 format)
        reader.readAsDataURL(input.files[0])
      }
    },
  },
  computed: {
    /**
     * @vuese
     * This function to return list categories
     */
    getCategories () {
      let cat = {}
      _.forEach(this.$store.state.marketplace.categories, (categories, categoryKey) => {
        cat[categoryKey] = this.catSelect(categories,categoryKey)
      })
      return cat
    },
    /**
     * @vuese
     * This function to return list tribes world
     */
    getTribes () {
      return this.objectSelect(this.$store.state.marketplace.tribes, {value: 'id', title: 'name'})
    },
  }
}
</script>

<style lang="css" scoped>
</style>
