<template>
  <el-modal size="sm" :title="$t(module+'.product.modal.edit.title')" id="market-place-product-edit">
    <form @submit.prevent="EditProduct()">
      <el-alert type="warning" v-if="formError !== false && formError != ''">
        <span v-html="formError" />
      </el-alert>
      <template v-if="permissionEdit">
        <div class="form-group">
          <label for="name" class="label-required font-weight-bold">{{ $t(module+'.product.modal.name') }}</label>
          <input v-model="product.name" required type="text" class="form-control" id="name" :placeholder="$t(module+'.product.modal.palceholder.name')">
        </div>
        <div class="form-group" >
          <label for="description" class="label-required font-weight-bold">{{ $t(module+'.product.modal.description') }}</label>
          <textarea v-model="product.description" class="form-control" :placeholder="$t(module+'.product.modal.palceholder.description')"/>
        </div>
        <div class="form-group">
          <label for="fournisseur" class="font-weight-bold">{{ $t(module+'.product.modal.fournisseur') }}</label>
          <input v-model="product.fournisseur" required type="text" class="form-control" id="fournisseur" :placeholder="$t(module+'.product.modal.palceholder.fournisseur')">
        </div>
        <div class="form-group">
          <label for="price" class="font-weight-bold">{{ $t(module+'.product.modal.price') }}</label>
          <input v-model="product.price" type="number" min="1" class="form-control" id="price" :placeholder="$t(module+'.product.modal.palceholder.price')">
        </div>
        <div class="form-group">
          <label for="url" class="font-weight-bold">{{ $t(module+'.product.modal.url') }}</label>
          <input v-model="product.url" required type="url" class="form-control" id="url" :placeholder="$t(module+'.product.modal.palceholder.url')" pattern="https?://.*" size="30">
        </div>
        <div class="form-group">
          <label for="categorie" class="font-weight-bold">{{ $t(module+'.product.modal.categorie') }}</label>
          <el-select :options="getCategories['categorie']" :value="'value'" v-model="product.categorie" :placeholder="$t(module+'.product.modal.palceholder.categorie')" />
        </div>

        <div class="form-group" v-if="product.categorie">
          <label :for="'categories_' + product.categorie" class="font-weight-bold">{{ $t(module+'.product.modal.palceholder.categories_' + product.categorie) }}</label>
          <el-select :options="getCategories['categories_' + product.categorie]" :value="'value'" v-model="product['categories_' + product.categorie]" :placeholder="$t(module+'.product.modal.palceholder.categories_' + product.categorie)" />
        </div>

        <div class="form-group">
          <label for="tribe" class=" font-weight-bold">{{ $t('titles.world_tribes') }}</label>
          <el-select :options="getTribes" :multiple="true" :value="'value'" v-model="product.tribe" :placeholder="$t(module+'.product.modal.palceholder.tribe')" />
        </div>
        <div class="form-group">
          <label for="tag" class="label-requiredprice font-weight-bold">{{ $t(module+'.product.modal.tag') }}</label>
          <input-tag
            :required="false"
            v-model="product.tag"
            class="form-control"
            :placeholder="$t(module+'.product.modal.palceholder.tag')"
          />

        </div>
        <div class="file-upload-form">
          <label class="mt-2 mb-1 font-weight-bold">
            {{ $t(module+'.product.modal.picture') }}
          </label>
          <input ref="pictureInput" class="d-none" type="file" @change="previewPicture" accept="image/gif, image/jpeg, image/png" />
          <div v-if="image">
            <el-avatar v-if="image.base64" :src="image.base64"></el-avatar>
            <el-avatar v-if="image.thumb" :src="image.thumb"></el-avatar>
            <div @click="changePicture()" class="ml-2 btn btn-sm btn-light">{{ $t(module+'.product.modal.picture_change') }}</div>
          </div v-else >
          <div>
            <div @click="changePicture()" class="btn btn-sm btn-light" v-if="!image">{{ $t(module+'.product.modal.picture_add') }}</div>
          </div>
        </div>
      </template>
      <template v-else >
        <div class="form-group">
          <label for="tribe" class="label-required font-weight-bold">{{ $t('titles.world_tribes') }}</label>
          <el-select :options="getTribes" :multiple="true" :value="'value'" v-model="tribes" :placeholder="$t('titles.world_tribes')" />
        </div>
      </template>
      <div class="text-center">
        <button v-if="!product_loading" type="submit" class="btn btn-success text-white">{{ $t(module+'.product.modal.modifier') }}</button>
        <div v-if="product_loading" class="text-gray pt-1 pb-2"><loader /></div>
      </div>
    </form>
  </el-modal>
</template>

<script>
/**
 * @group _ Module marketplace
 * This component for modal edit product
 */

import _ from 'lodash'
import {
UPDATE_PRODUCT,
} from '../../mutations-types'

export default {
  name: "EditProduct",
  props: ['data'],
  data () {
    return  {
      module: 'modules.' + this.$route.meta.moduleName,
      product_loading: false,
      image: null,
      formError: false,
      listTribes: []
    }
  },
  methods: {
    /**
     * @vuese
     * This function for edit product
     */
    EditProduct () {
      this.product_loading = true
      if(this.image) this.product.image = this.image

      this.formError = ''
      if($.trim(this.product.name) == "") {
        this.formError+= '' + this.$t(this.module+'.product.modal.error.name') + '<br/>'
      }
      if($.trim(this.product.fournisseur) == "" ) {
        this.formError+= '' + this.$t(this.module+'.product.modal.error.fournisseur') + '<br/>'
      }
      if(this.formError == '') {
        this.formError = false
      } else {
        return
      }
      if(!this.permissionEdit) {
        let newTribes = []
        newTribes.push(...this.listTribes, ...this.tribesNotInWorld)
        this.product.tribe = newTribes
      }
      this.$store.dispatch('marketplace/'+UPDATE_PRODUCT, {data: this.product , world: this.$store.getters['auth/getWorld'].id}).then(resp => {
        this.image = null
        this.product.image = resp['product'][0].image
        this.notifSuccess(resp[0])
        this.$emit('reloadChild');
        this.closeModal('market-place-product-edit')
        this.product_loading = false
      })
      .catch(error => {
        this.product_loading = false
        this.notifError(error)
      })
    },
    /**
     * @vuese
     * This function to return array for el-select
     */
    catSelect (data, categoryKey) {
      let list = []
      _.map(data, item => {
          list.push({ value: item, title: this.$t(this.module + '.product.categories.' + categoryKey + '.' + item) })
      })
      return list
    },
    /**
     * @vuese
     * This function for return list for component el-select
     */
    objectSelect (data, key = null) {
      let list = []
      data.map(item => {
        if(key == null)
          list.push({ value: item, title: item })
        else
          list.push({ value: item[key.value], title: item[key.title] })
      })
      return list
    },
    /**
    * @vuese
    * change picher profile
    */
    changePicture () {
      this.image = null
      this.$refs.pictureInput.click()
    },

    async previewPicture (event) {
      // Reference to the DOM input element
      var input = event.target
      // Ensure that you have a file before attempting to read it
      if (input.files && input.files[0]) {
        // create a new FileReader to read this image and convert to base64 format
        var reader = new FileReader()
        // Define a callback function to run, when FileReader finishes its job
        reader.onload = (e) => {
          // Note: arrow function used here, so that "this.imageData" refers to the imageData of Vue component
          // Read image as base64 and set to imageData
          this.image = {}
          this.image.base64 = e.target.result
        }
        // Start the reader job - read file as a data url (base64 format)
        reader.readAsDataURL(input.files[0])
      }
    },
  },
  computed: {
    /**
     * @vuese
     * This function return list categories
     */
    getCategories () {
      let cat = {}
      _.forEach(this.$store.state.marketplace.categories, (categories, categoryKey) => {
        cat[categoryKey] = this.catSelect(categories,categoryKey)
      })
      return cat
    },
    /**
     * @vuese
     * This function liste tribes world
     */
    getTribes () {
      return this.objectSelect(this.$store.state.marketplace.tribes, {value: 'id', title: 'name'})
    },
    /**
     * @vuese
     * This function return product selected
     */
    product () {
      return this.data
    },
    /**
     * @vuese
     * This function return tribes new selected
     */
    tribes: {
      get: function () {
        return this.data.tribe
      },
      set: function (newValue) {
        this.listTribes = []
        newValue.map(item => {
          this.listTribes.push(parseInt(item))
        })
        return newValue
      }
    },
    tribesNotInWorld() {
      let data = []
      if(this.product.tribe != null) {
        this.product.tribe.map(item => {
          let status = false
          this.$store.state.marketplace.tribes.map(elem => {
            if(elem.id == item) {
              status = true
            }
          })
          if(!status) {
            data.push(item)
          }
        })
      }
      return data
    },
    /**
     * @vuese
     * This function for check permission MO product or OM product
     */
    permissionEdit () {
      return !this.data.mo_product
    }
  }
}
</script>

<style lang="css" scoped>
</style>
