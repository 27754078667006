<!-- Component Commentaire -->
<template>
  <div>
    <el-alert type="warning" v-if="noComment">
      <span> {{ $t(module+'.product.comments.vide') }} </span>
    </el-alert>
    <el-alert type="warning" v-if="maxLength">
      <span> {{ $t(module+'.product.comments.maxlenth') }} </span>
    </el-alert>
    <textarea
      class="comment form-control w-100"
      :placeholder="$t('todos.comment_add')"
      v-model="comment"
      @input="onChange"
      :maxlength="sizeCommment"
    >
    </textarea>
    <div class="text-right mt-2">
      <span @click="addComment(comment)" class="btn btn-primary text-white btn-sm w-100">
        {{ $t(module+'.product.comments.comment_send') }}
      </span>
    </div>

    <div v-if="listComments.length > 0" class="small text-grey text-center mt-3 text-center" @click="commentsOpened = !commentsOpened">
      <div class="block cursor-pointer" v-if="commentsOpened">{{ $t('todos.comment_view') }} ({{listComments.length }})</div>
      <div class="block cursor-pointer" v-if="!commentsOpened">{{ $t('todos.comment_close') }} ({{ listComments.length }})</div>
    </div>

    <div v-if="listComments.length == 0 && !commentsOpened" class="small text-center mt-3 text-grey">
      {{ $t('todos.comment_none') }}
    </div>
    <div class="mt-2" v-if="!commentsOpened">
      <ul class="media-list">
          <li class="media" v-for="item in listComments">
            <img :src="userComment(item.user_id).avatar.thumb" class="avatar mt-1 rounded-circle mr-2">
            <div class="media-body">
              <div class="text-primary font-weight-semibold">
                {{ userComment(item.user_id).full_name}}
              </div>
             <span class="text-muted d-block">
               {{ item.content }}
             </span>
           </div>
          </li>
      </ul>
    </div>
  </div>
</template>

<script>
/**
 * @group _ Module marketplace
 * This component for Commentaires
 */
import {
STORE_COMMENT
} from '../../mutations-types'
export default {
  props: ['id'],
  name: 'Comments',
  data () {
    return {
      commentsOpened: false,
      comment: null,
      module: 'modules.'+this.$route.meta.moduleName,
      noComment: false,
      maxLength: false,
      sizeCommment: 150
    }
  },
  methods: {
    /**
     * @vuese
     * This function for add Comments on product
     */
    addComment (comment) {
      if ($.trim(comment) !== '' && comment.length <= this.sizeCommment) {
        let object = {
          product: this.id,
          content: comment
        }
        this.$store.dispatch('marketplace/'+ STORE_COMMENT, {world: this.worldId, data: {comment: object}}).then((resp) => {
        })
        comment = ''
        this.comment = ''
      } else {
        this.noComment = true
      }
    },
    /**
     * @vuese
     * This function get user auther comment
     */
    userComment (author) {
      let userComment = {}
      this.$store.state.marketplace.users.map(item => {
        if(author == item.id) {
          return userComment = item
        }
      })
      return userComment
    },
    /**
     * @vuese
     * This function check on length comment
     */
    onChange () {
      if(this.comment.length > 0) {
          this.noComment = false
      }

     this.maxLength = this.comment.length > this.sizeCommment
    }
  },
  computed: {
    /**
     * @vuese
     * This function get data comments on product
     */
    listComments () {
      let comments = this.$store.state.marketplace.comments.filter(item => {return (item.id == this.id && item.world_id == this.worldId)})
      return comments.sort((a, b) => {return new Date(b.created_at) - new Date(a.created_at)})
    },
    worldId () {
      return this.$store.getters['auth/getWorld'].id
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
