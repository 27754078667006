<template>
<div>
  <div v-if="data && data.length > 0" class="row">
    <div v-for="(item) in data" :key="item.id" class="col-xxl-6">
      <div class="card">
        <div class="card-body">
          <div class="row align-items-center">
            <div class="col-lg-3 col-xl-2 text-center">
              <router-link tag="a" :to="target(item)">
                <img v-if="item.image" :src="item.image.thumb" alt="" class="product-image">
              </router-link>
            </div>
            <div class="col-lg-5 col-xl-6">
              <router-link :to="target(item)" :meta="item.id" class="c-pointer">
                <el-card-title :title="item.name" />
              </router-link>
              <div class="tags mb-2" v-if="item.tag">
                <span v-for="(tag, tagkey) in item.tag" :key="tagkey" class="badge badge-info mr-1 mb-1">
                  {{ tag }}
                </span>
              </div>
              <div class="small text-success font-weight-bold text-uppercase mb-2" v-if="item.perk && item.perk != ''">
                {{ item.perk }}
              </div>
              <div v-if="item.description && item.description != ''">
                {{ item.description.length > desclimit ? item.description.substring(0, desclimit) + ' ...' : item.description  }}
                <div class="mt-3 text-grey">
                  <span class="d-inline-block mr-5">
                    • {{ $t(module + '.product.seller', { name: item.fournisseur }) }}
                  </span>
                </div>
              </div>
            </div>
            <div class="col-lg-4 text-center">
              <div v-if="OfficeManager" class="d-flex justify-content-end mb-1">
                <span class="icon-pencil text-slate cursor-pointer" @click="openModalEdit(item)" data-toggle="tooltip" :title="$t(module + '.tooltip.edit')"></span>
                <span v-if="!item.mo_product" class="icon-cancel-circle2 text-danger cursor-pointer cancel-icon" @click="openModalDelete(item)" data-toggle="tooltip" :title="$t(module + '.tooltip.delete')"></span>
              </div>
              <h2 class="m-0 font-weight-bold" v-if="item.price" v-html="currency(item.price, symbol(item.currency || '€'))"></h2>

              <div class="mb-2">
                <ProductOrderButton v-if="renderComponent && !OfficeManager" :id="item.id" :price="item.price"></ProductOrderButton>
                <a tag="button" :href="item.url" target="_blank" class="btn btn-success" v-if='OfficeManager'>
                  {{ $t(module + '.product.order.achater') }} <i class="icon-new-tab small" />
                </a>
              </div>

              <div class="mb-1 text-center" v-if='OfficeManager'>
                <star-rating :show-rating="false" :increment="0.5" :max-rating ="5" :star-size="20" clas="icon-star-reting text-center" @rating-selected="retingSlected($event, item)" :rating="item.votes ? item.votes : reting(item.users_votes)" />
                <div class="text-grey font-weight-semibold small" v-if="item.votes > 0 && checkVote(item)">
                  <span v-if="reting(item.users_votes)">
                    {{ $t(module + '.product.vote_user', { nb: parseFloat(reting(item.users_votes)).toFixed(1). replace('.0', '') }) }} —
                  </span>
                  {{ $t(module + '.product.vote_moyenne', { nb: parseFloat(item.votes).toFixed(1).replace('.0', '') }) }}
                </div>
              </div>

              <div class="mb-1 text-center" v-if='!OfficeManager'>
                <star-rating :show-rating="false" :increment="0.5" :max-rating ="5" :star-size="20" :read-only="true" clas="icon-star-reting text-center" :rating="item.votes ? item.votes : reting(item.users_votes)" />

              </div>
<!--               <div class="text-grey" v-if="(item.nomber_votes_initiale || item.nomber_votes) && reviews(item.nomber_votes_initiale, item.nomber_votes) > 0">
                {{ $t(module + '.product.reviews', { nb: reviews(item.nomber_votes_initiale, item.nomber_votes) }) }}
              </div> -->


              <div class="mt-2">
                <p @click="wishlist(item.id, typeActionOnwishList(item.id))" class="font-weight-semibold small text-dark cursor-pointer">
                  <span v-if="typeActionOnwishList(item.id) == 'add'">{{ $t(module + '.product.wishList.add') }}</span>
                  <span v-if="typeActionOnwishList(item.id) == 'remove'">{{ $t(module + '.product.wishList.remove') }}</span>

                  <span :class="typeActionOnwishList(item.id) == 'remove' ? 'ml-1 icon-heart5 text-primary' : 'ml-1 icon-heart6 text-primary'"></span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <EditProduct v-if="OfficeManager" :data="elemEdit" @reloadChild="reloadChild"></EditProduct>
    <DeleteProduct v-if="OfficeManager" :data="elemDelete"></DeleteProduct>
  </div>
  <el-empty v-else :title="$t(module + '.product.empty.title')" :text="$t(module + '.product.empty.text')"></el-empty>
</div>
</template>

<script>
/**
 * @group _ Module marketplace
 * This component for single product on listing product
 */

import ProductOrderButton from './_ProductOrderButton'
import EditProduct from './_EditProduct'
import DeleteProduct from './_DeleteProduct'
import StarRating from 'vue-star-rating'


import {
  WISHLIST,
  UPDATE_PRODUCT
} from '../../mutations-types'

export default {
  name: 'SingleProduct',
  props: {
    data: {
      type: Array,
      required: true,
    },
    desclimit: {
      type: Number,
      default: 300,
    },
  },
  components: {
    ProductOrderButton,
    EditProduct,
    DeleteProduct,
    StarRating
  },
  data() {
    return {
      module: 'modules.' + this.$route.meta.moduleName,
      world_id: this.$store.getters['auth/getWorld'].id,
      elemEdit: {},
      elemDelete: {},
      renderComponent: true
    }
  },
  mounted() {
    this.$nextTick(function() {
      $('[data-toggle="tooltip"]').each(function() {
        if ($(this).attr('title') === '') {
          $(this).attr('title', $(this).next('.tooltip').html())
        }
      }).tooltip({
        html: true,
        placement: 'bottom'
      }).each(function() {
        $(this).attr('title', '')
      })
    })
  },
  methods: {
    /**
     * @vuese
     * This function return lien for product details
     */
    target(item) {
      let name  = item.name.replace(/[`~!@#$%^&*()_\-+=\[\]{};:'"\\|\/,.<>?\s]/g, ' ').replace(/ /g, '-')
      return `/world/${this.world_id}/marketplace/${item.id}/${name}/product`
    },
    /**
     * @vuese
     * This function open modal edit
     */
    openModalEdit(elem) {
      this.elemEdit = elem
      this.openModal('market-place-product-edit')
    },
    /**
     * @vuese
     * This function add to wishlist user or remove form wishlist
     */
    wishlist(id, type) {
      this.$store.dispatch('marketplace/' + WISHLIST, {
        data: {
          id: id,
          type: type
        },
        world: this.$store.getters['auth/getWorld'].id
      }).then(resp => {})
    },
    /**
     * @vuese
     * This function return type wish list add or remove
     */
    typeActionOnwishList(id) {
      return (this.$store.state.marketplace.wishlist && this.$store.state.marketplace.wishlist.length > 0 && this.$store.state.marketplace.wishlist.includes(id)) ? 'remove' : 'add'
    },
    /**
     * @vuese
     * This function open modal delete
     */
    openModalDelete(elem) {
      this.elemDelete = elem
      this.openModal('market-place-product-delete')
    },
    reloadChild() {
      // Remove my-component from the DOM
      this.renderComponent = false;

      this.$nextTick(() => {
        // Add the component back in
        this.renderComponent = true;
      });
    },
    /**
     * @vuese
     * This function return symbol of currency in world or for product
     */
    symbol(id) {
      let symbol = null
      if(id == null) {
        id = this.$store.state.marketplace.world_currency
      }
      this.$store.state.marketplace.currency.map(item => {
        if(item.id == id) {
          symbol = item.symbol
        }
      })
      return symbol
    },
    /**
     * @vuese
     * This function return vote for user
     */
    reting (list) {
      if(list == null) {
        return 0
      }

      let voteUser = list.find(item => item.user == this.userid)
      return typeof voteUser == 'undefined' ? 0 : voteUser.vote
    },
    /**
     * @vuese
     * This function return totla vote
     */
    retingTotal (nbr) {
      return nbr == null ? 0 : nbr
    },
    /**
     * @vuese
     * This function calcul new vote for product
     */
    retingSlected (e, product) {
      if(product.votes == 0 || product.votes == null) {
        product.nomber_votes_initiale = product.nomber_votes_initiale == null ? 0 :parseInt(product.nomber_votes_initiale)
        product.note_vote_initiale = product.note_vote_initiale == null ? 0 : parseInt(product.note_vote_initiale)

        let votes = ((product.nomber_votes_initiale * product.note_vote_initiale)+e)/(product.nomber_votes_initiale + 1)
        product.votes = votes
        product.nomber_votes = product.nomber_votes_initiale + 1
        product.users_votes = []
        product.users_votes.push({user: this.userid, vote: e})
      } else {
        if(product.users_votes == null) {
          product.users_votes = []
        }
        let findUser = product.users_votes.find(item => item.user == this.userid)
        if(typeof findUser == "undefined") {
          let votes = ((parseInt(product.nomber_votes) * parseInt(product.votes))+e)/(parseInt(product.nomber_votes) + 1)
          product.votes = votes
          product.nomber_votes = parseInt(product.nomber_votes) + 1
          product.users_votes.push({user: this.userid, vote: e})
        } else {
          let nbr_votes = product.nomber_votes_initiale == null ? 0 : parseInt(product.nomber_votes_initiale)
          let votes = product.note_vote_initiale == null ? 0 : parseInt(product.note_vote_initiale)

          product.users_votes.map((item, key) => {
            if(item.user == this.userid) {
              product.users_votes[key].vote = e
            }
            votes = ((nbr_votes * votes)+item.vote)/(nbr_votes + 1)
            nbr_votes = nbr_votes + 1
          })
          product.votes = votes
        }
      }
      this.$store.dispatch('marketplace/'+UPDATE_PRODUCT, {data: product , world: this.$store.getters['auth/getWorld'].id}).then(resp => {
      })
    },
    /**
     * @vuese
     * This function count nomber users vote on product
     */
    reviews (initial, nbr) {
      return (nbr == null ? 0 : parseInt(nbr)) + (initial == null ? 0 : parseInt(initial))
    },
    /**
     * @vuese
     * This function return false if user connected is only person how vote
     */
    checkVote (product) {
      if(!product.users_votes || product.users_votes.length == 0) {
        return false
      }
      let findUser = product.users_votes.find(item => item.user == this.userid)
      if(typeof findUser != "undefined" && (!product.users_votes || product.users_votes.length == 1) ) {
        return false
      }
      return true
    }
  },
  computed: {
    /**
     * @vuese
     * This function sheck type user if is Office manager
     */
    OfficeManager() {
      return this.$store.getters['auth/isWorldOwner'];
    },
    /**
     * @vuese
     * This function return user id (User connectd)
     */
    userid() {
      return this.$store.state.auth.profile.id
    }
  }
}
</script>

<style scoped lang="scss">
.product-image {
  max-width: 100%;
  width: 250px;
}
.cancel-icon {
    margin-left: 15px;
}
// .tooltip-inner {
//     box-shadow: 1px 4px 14px rgba(0,0,0,.3);
//     padding-top: 15px;
//     padding-bottom: 15px;
// }
// .tooltip-inner {
//     max-width: 430px !important;
//     padding: 0.5rem 0.75rem !important;
//     color: #fff !important;
//     text-align: center;
//     background-color: #ef7e3c !important;
//     border-radius: 0.1875rem !important;
// }
.width-title {
  width: fit-content;
}
.vue-star-rating-star {
  // height: 23px;
  // width: 23px;
}
.vue-star-rating-rating-text {
  position: absolute;
    right: -25px;
    top: 2px;
}
.vue-star-rating {
  position: relative;
}
.vue-star-rating{
  justify-content: center;
}
</style>
