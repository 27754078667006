<template>
  <auth-content>
    <el-header slot="header" :title="$t(module + '.title')">
      <div slot="left">
        <el-header-back />
      </div>
    </el-header>
      <div class="mb-4">
      <SingleProduct :data="[product]" :desclimit="9999999" />
      <div class="mb-4" v-if="product.rating && product.rating.title ">
        <h3 class="mt-0 mb-4">{{ product.rating.title }}</h3>
      </div>
        <el-chart-radar v-if="product.rating" :data="product.rating" />
    </div>
<!--     <div slot="sidebar">
      <Comments :id="product.id"></Comments>
    </div> -->
  </auth-content>
</template>

<script>
/**
 * @group _ Module marketplace
 * This component for delails product
 */
import component from "./component"
import store from '@/store'
import {
GET_CATEGORIES_LIST,
GET_PRODUCT_REQUEST,
REQUEST_USERS
} from '../mutations-types'
export default {
  name: "Details",
  components: {
    SingleProduct: component.SingleProduct,
    // ProductDetails: component.ProductDetails,
    // Comments: component.Comments
  },
  data () {
    return {
      module: 'modules.' + this.$route.meta.moduleName,
      product: {}
    }
  },
  mounted () {
    this.$store.dispatch('marketplace/' + GET_CATEGORIES_LIST, this.$store.getters['auth/getWorld'].id).then(resp => {
    })
    this.$store.dispatch('marketplace/'+GET_PRODUCT_REQUEST, this.$store.getters['auth/getWorld'].id).then(resp => {
    })
    this.$store.dispatch('marketplace/'+REQUEST_USERS, this.$store.getters['auth/getWorld'].id).then(resp => {
    })
    this.$store.state.marketplace.products.map(item => { if(item.id == this.$route.params.productId) {this.product = item}})
    if(Object.keys(this.product).length == 0) {
      this.$router.push({'name': 'marketplace.index'})
    }
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
</style>
