<template>
  <el-modal size="sm" id="market-place-product-delete" :title="$t(module+'.product.modal.delete.title')">
    <form @submit.prevent="deleteProduct()" class="text-center">
      <div v-if="!product_loading" class="form-check mb-3">
        <label class="form-tribescheck-label">
          <input type="checkbox" required class="form-check-input" v-model="remove_product">
          {{ $t(module+'.product.modal.delete.msg_confermation') }}
        </label>
      </div>
      <div class="text-center text-center">
        <button v-if="!product_loading" type="submit" class="btn btn-lg btn-danger text-white">{{ $t(module+'.product.modal.remove') }}</button>
        <div v-if="product_loading" class="text-gray pt-1 pb-2">
          <loader />
        </div>
      </div>
    </form>
  </el-modal>
</template>

<script>
/**
 * @group _ Module marketplace
 * This component for modal delete product
 */
import {
DELETE_PRODUCT
} from '../../mutations-types'

export default {
  name: 'DeleteProduct',
  props: ['data'],
  data () {
    return {
      module: 'modules.' + this.$route.meta.moduleName,
      product_loading: false,
      remove_product: false
    }
  },
  methods: {
    /**
     * @vuese
     * This function for delete product after confirmation
     */
    deleteProduct () {
      this.product_loading = true
      this.$store.dispatch('marketplace/'+DELETE_PRODUCT, {product: this.data.id , world: this.$store.getters['auth/getWorld'].id}).then(resp => {
        this.notifSuccess(resp[0])
        this.product_loading = false
        this.remove_product = false
        this.closeModal('market-place-product-delete')
        this.$emit('pushRouteBack');
      })
    }
  }
}
</script>

<style lang="css" scoped>
</style>
