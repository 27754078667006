<template>
  <div class="">
    <button v-if="!listCommands.includes(id)" @click="storeCommand(id)"  class="btn btn-success text-white">
      <i class="icon-cart mr-1" />
      {{ $t(module+'.product.order.add') }}
    </button>
    <button v-else @click="storeCommand(id)"  class="btn btn-gray text-grey text-break pb-1">
      <i class="icon-cart mr-1" />
      {{ $t(module+'.product.order.annule') }}
    </button>
<!--     <div class="tooltip d-none">
      <div class="row py-1">
        <div class="text-left">
          <div>
            {{ $t('product.add_to_cart.text_1') }}
          </div>
          <div class="mb-2" v-html="$t('product.add_to_cart.text_2', { price: price })">
          </div>
          <div>
            {{ $t('product.add_to_cart.text_3') }}
          </div>
        </div>
      </div>
    </div> -->

  </div>
</template>

<script>
/**
 * @group _ Module marketplace
 * This component for Button Command
 */
import {
COMMAND_PRODUCT
} from '../../mutations-types'

export default {
  name: 'ProductOrderButton',
  props: [
    'id',
    'price'
  ],
  data () {
    return {
      module: 'modules.' + this.$route.meta.moduleName,
    }
  },
  created () {
  },
  mounted () {
    // Tooltip
    this.$nextTick(function () {
      $('[data-toggle="tooltip"]').each(function () {
        if ($(this).attr('title') === '') {
          $(this).attr('title', $(this).next('.tooltip').html())
          $(this).next('.tooltip').remove()
        }
      }).tooltip({
        html: true,
        placement: 'bottom'
      }).each(function () {
        $(this).attr('title', '')
      })
    })
  },
  methods: {
    /**
     * @vuese
     * This function for add Command new command to user or delete command
     */
    storeCommand (id) {
      this.$store.dispatch('marketplace/'+COMMAND_PRODUCT, {data: {product_id: id} , world: this.$store.getters['auth/getWorld'].id}).then(resp => {
        this.notifSuccess(resp.msg)
      })
    }
  },
  computed: {
    /**
     * @vuese
     * This function return list command user connected
     */
    listCommands () {
      return this.$store.state.marketplace.listCommands
    }
  }
}
</script>

<style lang="scss" scoped>
.text-break {
  white-space: normal;
}
</style>
