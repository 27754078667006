<template>
  <auth-content>

    <el-header slot="header" :title="$t(module + '.title')" permissions="marketplace">
      <el-header-action
        v-if="userHasPermission('marketplace', 'manage_module')"
        size="xs"
        slot="actions"
        icon="add"
        :label="$t(module + '.product.create')"
        @click.native="openModal('market-place-product-create')"
      />
    </el-header>

    <el-filters v-if="productsLoaded && products && products.length > 0" :filters="filters" />

    <div v-if="!productsLoaded">
      <div class="pt-2">
        <div class="falseaction card bg-white mb-3 align-items-center justify-content-between d-flex" v-for="i in 3" :style="'animation-delay: .' + (i) + 's'">
          <div class="bg-secondary falsecheckbox" />
          <div class="falsection-text-area">
            <div class="bg-secondary falsetext" />
            <div class="bg-secondary falsetext" />
            <div class="bg-secondary falsetext" />
          </div>
        </div>
      </div>
    </div>
    <SingleProduct v-if="productsLoaded && products && products.length > 0" :data="filteredProducts" />
    <el-empty v-if="productsLoaded && products && products.length == 0" :title="$t(`modules.marketplace.product.list_empty.title`)" :text="$t(`modules.marketplace.product.list_empty.text`)"/>

    <div slot="sidebar">
      <div class="card" v-if='getRecentComment.length > 0'>
        <div class="card-header bg-transparent header-elements-inline">
          <span class="text-uppercase font-size-sm font-weight-semibold">
            {{ $t(module + '.product.comments.title') }}
          </span>
        </div>
        <div class="card-body">
          <ul class="media-list">
              <li class="media" v-for="item in getRecentComment">
                <img :src="userComment(item.user_id).avatar.thumb" class="avatar mt-1 rounded-circle mr-2">
                <div class="media-body">
                  <div class="text-primary font-weight-semibold">
                    {{ userComment(item.user_id).full_name}}
                  </div>
                 <span class="text-muted d-block">
                   {{ item.content }}
                 </span>
               </div>
              </li>
          </ul>
        </div>
      </div>
    </div>
    <CreateProduct />
  </auth-content>
</template>

<script>
/**
 * @group _ Module marketplace
 * This component for listing product on world
 */
import _ from "lodash"
import component from "./component"
import store from '@/store'
import {
GET_PRODUCT_REQUEST,
REQUEST_USERS,
LIST_COMMANDS_USER
} from '../mutations-types'
import Fuse from 'fuse.js'

export default {
  name: 'Index',
  components: {
    SingleProduct: component.SingleProduct,
    CreateProduct: component.CreateProduct
  },
  data () {
    return {
      module: 'modules.' + this.$route.meta.moduleName,
      productsLoaded: false
    }
  },
  mounted() {

    store.dispatch('marketplace/' + GET_PRODUCT_REQUEST, store.getters['auth/getWorld'].id).then(resp => {
      this.productsLoaded = true
    })
    store.dispatch('marketplace/' + REQUEST_USERS, store.getters['auth/getWorld'].id)
    store.dispatch('marketplace/' + LIST_COMMANDS_USER, store.getters['auth/getWorld'].id)
    this.loadFilters()
  },
  methods: {
    /**
     * @vuese
     * This function for load filters
     */
    loadFilters () {
      let produits = _.cloneDeep(this.formatProducts())

      let filters = []
      filters.push({
        title: this.$t(this.module + '.filters.categories'),
        key: 'categorie',
        options: this.getFilterOptions(produits, 'categorie', this.$t(this.module + '.product.categories.categorie')),
      })

      _.forEach(this.$store.state.marketplace.categories, (categories, categoryKey) => {
        if (this.$store.state.global.sidebarFilters.categorie && this.$store.state.global.sidebarFilters.categorie.includes(categoryKey.replace('categories_', ''))) {
          filters.push({
            title: this.$t(this.module + '.product.modal.palceholder.' + categoryKey),
            key: categoryKey,
            options: this.getFilterOptions(produits, categoryKey, this.$t(this.module + '.product.categories.' + categoryKey)),
          })
        }
      })

      filters.push({
        title: this.$t(this.module + '.filters.tags'),
        key: 'tag_filter',
        options: this.getFilterOptions(produits, 'tag_filter', this.$t(this.module + '.filters.tag_filter.options')),
      })
      filters.push({
        title: this.$t(this.module + '.filters.tribe'),
        key: 'tribe_filter',
        options: this.getFilterOptions(produits, 'tribe_filter', this.$t(this.module + '.filters.tribe_filter.options')),
      })

      this.$store.commit('marketplace/setCurrentFilters', filters)
    },
    /**
     * @vuese
     * This function add type filter to products
     */
    formatProducts () {
      return this.products.map(product => {

        if(product.tag != null && product.tag.length > 0) {
          product['tag_filter'] = []
          product.tag.forEach(tag => {
            product['tag_filter'].push(tag)
          })
        }
        if(product.tribe != null && product.tribe.length > 0) {
          product['tribe_filter'] = []
          store.state.marketplace.tribes.map(tribe => {
            product.tribe.map((i, index) => {return product.tribe[index] = parseInt(i)})
            if(product.tribe.includes(tribe.id)) {
              product['tribe_filter'].push(tribe.name)
            }
          })
        }
        return product
      })
    },
    /**
     * @vuese
     * This function get auther commentaire
     */
    userComment (author) {
      let userComment = {}
      this.$store.state.marketplace.users.map(item => {
        if(author == item.id) {
          return userComment = item
        }
      })
      return userComment
    },
  },
  computed: {
    /**
     * @vuese
     * This function return list products in world
     */
    products () {
      return store.state.marketplace.products
    },
    /**
     * @vuese
     * This function return list products after add type filters
     */
    formattedProducts () {
      return this.formatProducts()
    },
    /**
     * @vuese
     * This function for filter by search
     */
    filteredProducts () {
      // get data filterd by filters true
      let produits = this.applyDataFilters(this.formattedProducts, this.filters)
      this.loadFilters()
      if (this.searchValue === '') {
        return _.slice(produits, 0, 10)
      }
      let searchFuse = new Fuse(produits, {
        shouldSort: false,
        threshold: 0.3,
        location: 0,
        distance: 100,
        minMatchCharLength: 1,
        keys: [
          "name",
        ]
      }).search(this.searchValue)

      return _.slice(searchFuse, 0, 10)
    },
    /**
     * @vuese
     * This function return searchValue
     */
    searchValue: {
      get () {
        return this.$store.state.global.sidebarSearch
      },
      set (value) {
        this.$store.commit('global/changeSidebarSearch', value)
      }
    },
    /**
     * @vuese
     * This function return current value filter
     */
    filters () {
      return this.$store.state.marketplace.currentFilters
    },
    /**
     * @vuese
     * This function return last commentaires on all products
     */
    getRecentComment () {
      let comments = this.$store.state.marketplace.comments.filter(item => {return (item.world_id == this.worldId)})
      return comments
      .sort((a, b) => {return new Date(b.created_at) - new Date(a.created_at)})
      .slice(0, 4)
    },
    wishlist () {
      return store.state.marketplace.wishlist
    },
    worldId () {
      return this.$store.getters['auth/getWorld'].id
    }
  },
  watch: {
    // products: {
    //   handler: function (val, oldVal) {
    //     this.loadFilters()
    //   },
    //   deep: true
    // },
    wishlist: {
      handler: function (val, oldVal) {
        this.loadFilters()
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
.falseaction {
  height: 300px;
  .falsecheckbox {
    height: 150px !important;
    width: 250px !important;
  }
  .falsection-text-area {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 75%;
    height: 30%;
  }
}
@keyframes flickerAnimation {
  0%   { opacity:.8; }
  50%  { opacity:0.4; }
  100% { opacity:.8; }
}
.falseaction {
  animation: flickerAnimation 2s infinite;
  flex-direction: initial;
  .falsecheckbox {
    width: 16px;
    height: 16px;
    border-radius: 2px;
    margin: 0.75rem 0.875rem;
  }
  .falsetext {
    height: 12px;
    opacity: .1;
    width: 75%;
  }
  .falsepeople {
    width: 20px;
    height: 20px;
    border-radius: 100px;
  }
  .icon-star-full2 {
    opacity: .3;
  }
}
</style>
